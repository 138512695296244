import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Layout from "../components/layout";
import Seo from "../components/seo";

export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      contentfulTarieven(contentful_id: { eq: "3p7R4NKP2L8XpoJHrfjWcA" }) {
        id
        tarieven {
          json
        }
        body {
          json
        }
        contactGegevens {
          json
        }
        praktijkAdres {
          json
        }
        openingstijden {
          json
        }
        tarievenImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
          description
        }
      }
    }
  `);

  const { tarieven, body, contactGegevens, praktijkAdres, openingstijden, tarievenImage } = data.contentfulTarieven;

  return (
    <Layout>
      <Seo
        title="Contact"
        description="Contact met Praktijk voor Zelfliefde"
      />
      <Grid
        container
        spacing={0}
        alignItems="flex-start"
        justify="space-between"
        style={{ marginBottom: "4rem" }}
      >
        <Grid item xs={12} md={12}>
          <Paper elevation={0} style={{ width: "100%", height: "50vh" }} >
            <Img
              fluid={tarievenImage.fluid}
              fadeIn
              alt={tarievenImage.description}
              style={{ width: "100%", height: "100%" }}
              imgStyle={{ objectFit: 'contain' }}
            ></Img>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography component="div" color="textPrimary">
            {documentToReactComponents(body.json)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="div" color="textPrimary">
            {documentToReactComponents(contactGegevens.json)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="div" color="textPrimary">
            {documentToReactComponents(praktijkAdres.json)}
          </Typography>
        </Grid>
        <Grid item xs={false} md={1}></Grid>
      </Grid>
    </Layout>
  );
}
